export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const BASE_URL_GPS = process.env.REACT_APP_BASE_URL_GPS;
export const BASE_URL_IMAGE =  process.env.REACT_APP_BASE_URL_IMAGE;

export const Login = '/v1/auth/customer-login-gps';
export const Logout = 'auth/logout/';
export const User = 'users';
export const ChangePassword = '/v1/customers/webGps/changePin';
export const UpdateInfo = '/v1/customers/webGps/updateInfo';
export const ForgotPassword = 'auth/forgot-password';
export const ResetPassword = 'auth/reset-password';

export const Role = 'roles';
export const RolePermission = 'permissions';

export const setPassUser = 'users/set-password';

export const deviceLastLocation = '/gps/locationHistorys/last';
export const locationHistory = '/gps/locationHistorys'


export default {
  BASE_URL,
  BASE_URL_GPS,

  //User
  Login,
  User,
  Logout,
  ChangePassword,
  ForgotPassword,
  ResetPassword,

  //Role
  Role,
  RolePermission,

  // Set pass for user
  setPassUser,
  
  deviceLastLocation,
  locationHistory,
  UpdateInfo
};
