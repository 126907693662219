/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthLayout from './layouts/Auth';
import DashboardLayout from './layouts/Dashboard';
import ErrorLayout from './layouts/Error';
import { isMobile } from 'react-device-detect';

export default [
  {
    path: '/',
    exact: true,
    component: () => {
      if (!isMobile) {
        return <Redirect to="/last-location" />;
      }
      if (isMobile) {
        return <Redirect to="/mobile/last-location" />;
      }
    }
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('src/containers/Login'))
      },
      {
        path: '/auth/forgot-password',
        exact: true,
        component: lazy(() => import('src/containers/ForgotPass'))
      },
      {
        path: '/auth/reset-password',
        exact: true,
        component: lazy(() => import('src/containers/ResetPass'))
      },
      {
        path: '/auth/maintain',
        exact: true,
        component: lazy(() => import('src/containers/Maintain'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('src/views/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('src/views/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('src/views/Error500'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    route: '*',
    component: DashboardLayout,
    routes: [
      //!New
      {
        path: '/location-map',
        exact: true,
        component: lazy(() => import('./containers/LocationMap'))
      },
      {
        path: '/last-location',
        exact: true,
        component: lazy(() => import('./containers/LastLocation'))
      },
      {
        path: '/mobile/location-map',
        exact: true,
        component: lazy(() => import('./containers/Mobile/LocationMap'))
      },
      {
        path: '/mobile/last-location',
        exact: true,
        component: lazy(() => import('./containers/Mobile/LastLocation'))
      },
      {
        path: '/setting/user/:idUser/:tab',
        exact: true,
        component: lazy(() => import('./containers/UserSetting'))
      },
      {
        path: '/car-information',
        exact: true,
        component: lazy(() => import('./containers/CarInfor'))
      }
    ]
  }
];
