/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */

import LocalTaxiIcon from '@material-ui/icons/LocalTaxi';
import EditLocationAltIcon from '@material-ui/icons/EditLocation';
import HistoryIcon from '@material-ui/icons/History';

export default [
  {
    subheader: 'Main',
    items: [
      {
        title: 'Vị trí xe',
        href: '/last-location',
        icon: EditLocationAltIcon,
        role: ''
      },
      {
        title: 'Lịch sử di chuyển',
        href: '/location-map',
        icon: HistoryIcon,
        role: ''
      },
      {
        title: 'Danh sách xe',
        href: '/car-information',
        icon: LocalTaxiIcon,
        role: ''
      }
    ]
  }
];

export const navConfigMobile = [
  {
    subheader: 'Main',
    items: [
      {
        title: 'Vị trí xe',
        href: '/mobile/last-location',
        icon: EditLocationAltIcon,
        role: ''
      },
      {
        title: 'Lịch sử di chuyển',
        href: '/mobile/location-map',
        icon: HistoryIcon,
        role: ''
      },
      {
        title: 'Danh sách xe',
        href: '/car-information',
        icon: LocalTaxiIcon,
        role: ''
      }
    ]
  }
];
