import {
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  Slide,
  Typography
} from '@material-ui/core';
import { toUpper } from 'lodash-es';
import React from 'react';

const useStyle = makeStyles(theme => ({
  dialogWrapper: {
    // padding: theme.spacing(2),
    position: 'absolute',
    top: theme.spacing(1),
    borderRadius: '20px'
  }
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = 'Transition';

function Popup(props) {
  const classes = useStyle();
  const {
    title,
    children,
    openPopup,
    setOpenPopup = () => {},
    maxWidth
  } = props;
  const handleClose = () => {
    setOpenPopup(false);
  };
  return (
    <Dialog
      open={openPopup}
      maxWidth={!!maxWidth && maxWidth !== '' ? maxWidth : 'md'}
      TransitionComponent={Transition}
      classes={{ paper: classes.dialogWrapper }}
      onClose={handleClose}
    >
      <DialogTitle style={{ backgroundColor: '#F12323', padding: '25px' }}>
        <Typography
          variant="h6"
          align="center"
          component="div"
          style={{ color: 'white', fontWeight: '700' }}
        >
          {toUpper(title)}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
    </Dialog>
  );
}
export default Popup;
