import _ from 'lodash';
import { call, put, takeLatest } from 'redux-saga/effects';
import { DELETE, GET, PATCH, POST, GETGPS } from 'src/Service/ServiceBase';
import ServiceURL from 'src/Service/ServiceURL';
import TypeActions from '../TypeActions';

export function* getLastLocation(data) {
  const url = ServiceURL.deviceLastLocation;
  const callback = data.callback;
  try {
    const res = yield call(GETGPS, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.GET_LAST_LOCATION_FAILED,
        error: res.message
      });
    } else {
      yield put({
        type: TypeActions.GET_LAST_LOCATION_SUCCESS,
        data: res.data
      });
      callback && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_LAST_LOCATION_FAILED,
      error: error?.response?.data?.message
    });
    callback && callback.failed(error?.response?.data?.message);
  }
}

export function* getLocationHistory(data) {
  const url = ServiceURL.locationHistory + '?' + data.params;
  const callback = data.callback;
  try {
    const res = yield call(GETGPS, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.GET_LOCATION_HISTORY_FAILED,
        error: res.message
      });
    } else {
      yield put({
        type: TypeActions.GET_LOCATION_HISTORY_SUCCESS,
        data: res.data
      });
      callback && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_LOCATION_HISTORY_FAILED,
      error: error?.response?.data?.message
    });
    callback && callback.failed(error?.response?.data?.message);
  }
}

export default function* roleSaga() {
  yield takeLatest(TypeActions.GET_LAST_LOCATION_REQUEST, getLastLocation);
  yield takeLatest(
    TypeActions.GET_LOCATION_HISTORY_REQUEST,
    getLocationHistory
  );
}
