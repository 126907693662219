import _ from 'lodash';
import { call, put, takeLatest } from 'redux-saga/effects';
import { DELETE, GET, PATCH, POST } from 'src/Service/ServiceBase';
import ServiceURL from 'src/Service/ServiceURL';
import TypeActions from '../TypeActions';

export function* getListRole(data) {
  const url = ServiceURL.Role + '?' + data.params;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.GET_LIST_ROLE_FAILED,
        error: res.message
      });
    } else {
      yield put({
        type: TypeActions.GET_LIST_ROLE_SUCCESS,
        data: res.data.results
      });
      callback && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_LIST_ROLE_FAILED,
      error: error?.response?.data?.message
    });
  }
}

export function* getRole(data) {
  const url = ServiceURL.Role + '/' + data.params;
  try {
    const res = yield call(GET, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.GET_ROLE_FAILED,
        error: res.message
      });
    } else {
      yield put({
        type: TypeActions.GET_ROLE_SUCCESS,
        data: res.data
      });
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_ROLE_FAILED,
      error: error?.response?.data?.message
    });
  }
}

export function* createRole(data) {
  const url = ServiceURL.Role;
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.body);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.CREATE_ROLE_FAILED,
        error: res.message
      });
      callback && callback.failed(res.message);
    } else {
      yield put({
        type: TypeActions.CREATE_ROLE_SUCCESS
        // data: res.data.results
      });
      callback && callback.success();
    }
  } catch (error) {
    yield put({
      type: TypeActions.CREATE_ROLE_FAILED,
      error: error?.response?.data?.message
    });
    callback && callback.failed(error?.response?.data?.message);
  }
}

export function* editRole(data) {
  const url = ServiceURL.Role + '/' + data.params;
  const callback = data.callback;
  try {
    const res = yield call(PATCH, url, data.body);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.EDIT_ROLE_FAILED,
        error: res.message
      });
      callback && callback.failed(res.message);
    } else {
      yield put({
        type: TypeActions.EDIT_ROLE_SUCCESS
        // data: res.data.results
      });
      callback && callback.success();
    }
  } catch (error) {
    yield put({
      type: TypeActions.EDIT_ROLE_FAILED,
      error: error?.response?.data?.message
    });
    callback && callback.failed(error?.response?.data?.message);
  }
}
export function* deleteRole(data) {
  const url = ServiceURL.Role + '/' + data.params;
  const callback = data.callback;
  try {
    const res = yield call(DELETE, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.DELETE_ROLE_FAILED,
        error: res.message
      });
      callback && callback.failed(res.message);
    } else {
      yield put({
        type: TypeActions.DELETE_ROLE_SUCCESS
        // data: res.data.results
      });
      callback && callback.success();
    }
  } catch (error) {
    yield put({
      type: TypeActions.DELETE_ROLE_FAILED,
      error: error?.response?.data?.message
    });
    callback && callback.failed(error?.response?.data?.message);
  }
}

export function* getRolePermission() {
  const url = ServiceURL.RolePermission;
  try {
    const res = yield call(GET, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.GET_ROLE_PERMISSION_FAILED,
        error: res.message
      });
    } else {
      yield put({
        type: TypeActions.GET_ROLE_PERMISSION_SUCCESS,
        data: res.data
      });
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_ROLE_PERMISSION_FAILED,
      error: error?.response?.data?.message
    });
  }
}

export default function* roleSaga() {
  yield takeLatest(TypeActions.GET_LIST_ROLE_REQUEST, getListRole);
  yield takeLatest(TypeActions.GET_ROLE_REQUEST, getRole);
  yield takeLatest(TypeActions.CREATE_ROLE_REQUEST, createRole);
  yield takeLatest(TypeActions.EDIT_ROLE_REQUEST, editRole);
  yield takeLatest(TypeActions.DELETE_ROLE_REQUEST, deleteRole);
  yield takeLatest(TypeActions.GET_ROLE_PERMISSION_REQUEST, getRolePermission);
}
