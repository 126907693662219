import TypeActions from '../TypeActions';

const initialState = {
  listRole: [],
  roleById: {},
  rolePermission: {},
  isGetting: false,
  isCreating: false,
  isEditing: false,
  isDeleting: false,
  error: ''
};

export const roleReducer = (state = initialState, action) => {
  switch (action.type) {
    //!Get list role
    case TypeActions.GET_LIST_ROLE_REQUEST:
      return {
        ...state,
        isGetting: true
      };
    case TypeActions.GET_LIST_ROLE_SUCCESS:
      return {
        ...state,
        listRole: action.data,
        isGetting: false
      };
    case TypeActions.GET_LIST_ROLE_FAILED:
      return {
        ...state,
        error: action.error,
        isGetting: false
      };
    //!Get role
    case TypeActions.GET_ROLE_REQUEST:
      return {
        ...state,
        isGetting: true
      };
    case TypeActions.GET_ROLE_SUCCESS:
      return {
        ...state,
        roleById: action.data,
        isGetting: false
      };
    case TypeActions.GET_ROLE_FAILED:
      return {
        ...state,
        error: action.error,
        isGetting: false
      };
    //!Create role
    case TypeActions.CREATE_ROLE_REQUEST:
      return {
        ...state,
        isCreating: true
      };
    case TypeActions.CREATE_ROLE_SUCCESS:
      return {
        ...state,
        // listRole: action.data,
        isCreating: false
      };
    case TypeActions.CREATE_ROLE_FAILED:
      return {
        ...state,
        error: action.error,
        isCreating: false
      };
    //!Edit role
    case TypeActions.EDIT_ROLE_REQUEST:
      return {
        ...state,
        isEditing: true
      };
    case TypeActions.EDIT_ROLE_SUCCESS:
      return {
        ...state,
        // listRole: action.data,
        isEditing: false
      };
    case TypeActions.EDIT_ROLE_FAILED:
      return {
        ...state,
        error: action.error,
        isEditing: false
      };
    //!Delete role
    case TypeActions.DELETE_ROLE_REQUEST:
      return {
        ...state,
        isDeleting: true
      };
    case TypeActions.DELETE_ROLE_SUCCESS:
      return {
        ...state,
        // listRole: action.data,
        isDeleting: false
      };
    case TypeActions.DELETE_ROLE_FAILED:
      return {
        ...state,
        error: action.error,
        isDeleting: false
      };
    //!Get list role permission
    case TypeActions.GET_ROLE_PERMISSION_REQUEST:
      return {
        ...state,
        isGetting: true
      };
    case TypeActions.GET_ROLE_PERMISSION_SUCCESS:
      return {
        ...state,
        rolePermission: action.data,
        isGetting: false
      };
    case TypeActions.GET_ROLE_PERMISSION_FAILED:
      return {
        ...state,
        error: action.error,
        isGetting: false
      };
    default:
      return {
        ...state
      };
  }
};

export default roleReducer;
