import { combineReducers } from 'redux';
import { roleReducer } from './roleReducer';
import { userReducer } from './userReducer';
import { deviceReducer } from './deviceReducer';

const rootReducers = combineReducers({
  userReducer,
  roleReducer,
  deviceReducer
});
export default rootReducers;
