import { all, fork } from 'redux-saga/effects';
import roleSaga from './roleSaga';
import userSaga from './userSaga';
import deviceSaga from './deviceSaga'

export function* rootSagas() {
  yield all([
    fork(userSaga),
    fork(roleSaga),
    fork(deviceSaga),
  ]);
}
export default rootSagas;
