import React, { useEffect, useState } from 'react';
import { colors, Button, Typography } from '@material-ui/core';
import { Router, useLocation } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { createBrowserHistory } from 'history';
import MomentUtils from '@date-io/moment';
import { Provider as StoreProvider } from 'react-redux';
import { ThemeProvider, makeStyles } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { theme, themeWithRtl } from './theme';
import routes from './routes';
import { useDispatch } from 'react-redux';
import ReactNotification from 'react-notifications-component';
import GoogleAnalytics from './components/GoogleAnalytics';
import CookiesNotification from './components/CookiesNotification';
import userActions from 'src/Redux/Actions/userActions';
import ScrollReset from './components/ScrollReset';
import Popup from 'src/components/Popup';
import StylesProvider from './components/StylesProvider';
import DirectionToggle from './components/DirectionToggle';
import './mixins/chartjs';
import './mixins/moment';
import './mixins/validate';
import './mixins/prismjs';
import './mock';
import './assets/scss/main.scss';
import './App.css';
import store from './Store';

const history = createBrowserHistory();

const useStyles = makeStyles(theme => ({
  button: {
    color: 'white',
    margin: '2px',
    fontWeight: '700',
    backgroundColor: colors.red[600],
    '&:hover': {
      backgroundColor: colors.red[900],

      '@media (hover: none)': {
        backgroundColor: colors.red[600]
      }
    }
  }
}));

const DialogMaintaining = ({}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [maintaining, setMaintaining] = useState(false);

  useEffect(() => {
    dispatch(
      userActions.checkMaintain({
        success: data => {
          if (data.value) {
            setMaintaining(true);
            localStorage.clear();
            history.push('/auth/maintain');
          }
        },
        failed: () => {}
      })
    );
  }, [location.pathname]);
  return null;
  if (!maintaining) return null;
  return !location.pathname.includes('auth') ? (
    <Popup openPopup={maintaining} title="Thông báo" maxWidth="lg">
      <Typography gutterBottom variant="h3">
        Hệ thống đang được bảo trì, vui lòng quay lại sau
      </Typography>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          marginTop: 10
        }}
      >
        <Button
          size="medium"
          className={classes.button}
          onClick={() => {
            if (history.location.pathname.includes('auth')) {
              setMaintaining(false);
            } else {
              setMaintaining(false);
              localStorage.clear();
              history.push('/auth/login');
            }
          }}
        >
          Đồng ý
        </Button>
      </div>
    </Popup>
  ) : null;
};

function App() {
  const [direction, setDirection] = useState('ltr');
  const handleDirecitonToggle = () => {
    setDirection(prevDirection => (prevDirection === 'ltr' ? 'rtl' : 'ltr'));
  };

  return (
    <StoreProvider store={store}>
      <ThemeProvider theme={direction === 'rtl' ? themeWithRtl : theme}>
        <StylesProvider direction={direction}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Router history={history}>
              <DialogMaintaining history={history} />
              <ScrollReset />
              <GoogleAnalytics />
              <ReactNotification
                types={[
                  {
                    htmlClasses: ['notification']
                  }
                ]}
              />
              {/* <CookiesNotification /> */}
              {/* <DirectionToggle
                direction={direction}
                onToggle={handleDirecitonToggle}
              /> */}
              {renderRoutes(routes)}
            </Router>
          </MuiPickersUtilsProvider>
        </StylesProvider>
      </ThemeProvider>
    </StoreProvider>
  );
}

export default App;
