import TypeActions from '../TypeActions';

const initialState = {
  isGetting: false,
  isGettingLastLocation: false,
  isGettingLocationHistory: false,
  devices: [],
  lastLocation: {},
  locationHistory: []
};

export const deviceReducer = (state = initialState, action) => {
  switch (action.type) {
    //!Get list role
    case TypeActions.GET_DEVICE_REQUEST:
      return {
        ...state,
        isGetting: true
      };
    case TypeActions.GET_DEVICE_SUCCESS:
      return {
        ...state,
        devices: action.data,
        isGetting: false
      };
    case TypeActions.GET_DEVICE_FAILED:
      return {
        ...state,
        error: action.error,
        isGetting: false
      };
    //
    case TypeActions.GET_LAST_LOCATION_REQUEST:
      return {
        ...state,
        isGettingLastLocation: true
      };
    case TypeActions.GET_LAST_LOCATION_SUCCESS:
      return {
        ...state,
        lastLocation: action.data,
        isGettingLastLocation: false
      };
    case TypeActions.GET_LAST_LOCATION_FAILED:
      return {
        ...state,
        error: action.error,
        isGettingLastLocation: false
      };
    //
    case TypeActions.GET_LOCATION_HISTORY_REQUEST:
      return {
        ...state,
        isGettingLocationHistory: true
      };
    case TypeActions.GET_LOCATION_HISTORY_SUCCESS:
      return {
        ...state,
        locationHistory: action.data,
        isGettingLocationHistory: false
      };
    case TypeActions.GET_LOCATION_HISTORY_FAILED:
      return {
        ...state,
        error: action.error,
        isGettingLocationHistory: false
      };
    default:
      return {
        ...state
      };
  }
};

export default deviceReducer;
