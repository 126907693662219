import { LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { Suspense, useEffect, useState } from 'react';
import 'react-notifications-component/dist/theme.css';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router';
import { renderRoutes } from 'react-router-config';
import NavBar from './NavBar';
import TopBar from './TopBar';
import _ from 'lodash';

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: '100vh',
    display: 'flex',
    '@media all and (-ms-high-contrast:none)': {
      height: 0 // IE11 fix
    }
  },
  content: {
    paddingTop: 64,
    flexGrow: 1,
    maxWidth: '100%',
    overflowX: 'hidden',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 56
    }
  }
}));

function Dashboard({ route }) {
  //!Const
  const classes = useStyles();
  const history = useHistory();
  const userInfor = useSelector(state => state.userReducer.userInfor);

  //!State
  const [dataUser, setdataUser] = useState({});
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);
  //!UseEffect

  useEffect(() => {
    const expireAt = new Date(localStorage.getItem('expiresAt'));
    let token = localStorage.getItem('token');
    if (token && !_.isEmpty(token)) {
      if (new Date().getTime() > expireAt.getTime()) {
        localStorage.clear();
        history.push('/auth/login');
      }
    }
  }, [window.location.href]);

  if (
    !localStorage.getItem('token') ||
    localStorage.getItem('token') === '' ||
    !localStorage.getItem('expiresAt') ||
    localStorage.getItem('expiresAt') === '' ||
    !localStorage.getItem('userInfor') ||
    localStorage.getItem('userInfor') === '' ||
    !localStorage.getItem('id') ||
    localStorage.getItem('id') === '' ||
    !localStorage.getItem('devices') ||
    localStorage.getItem('devices') === ''
  ) {
    return <Redirect to="/auth/login" />;
  }
  return (
    <>
      <TopBar onOpenNavBarMobile={() => setOpenNavBarMobile(true)} />
      <NavBar
        onMobileClose={() => setOpenNavBarMobile(false)}
        openMobile={openNavBarMobile}
        dataUser={userInfor}
      />
      <div className={classes.container}>
        <div className={classes.content}>
          <Suspense fallback={<LinearProgress />}>
            {renderRoutes(route.routes)}
          </Suspense>
        </div>
      </div>
    </>
  );
}

Dashboard.propTypes = {
  route: PropTypes.object
};

export default Dashboard;
